import { DataLayerEvents } from "./events";

type EmptyEvents = {
  [Key in keyof DataLayerEvents]: undefined extends DataLayerEvents[Key]
    ? Key
    : never;
}[keyof DataLayerEvents];
type NonEmptyEvents = Exclude<keyof DataLayerEvents, EmptyEvents>;

export function sendDataLayerEvent<T extends EmptyEvents>(event: T): void;
export function sendDataLayerEvent<T extends NonEmptyEvents>(
  event: T,
  params: DataLayerEvents[T]
): void;
export function sendDataLayerEvent<T extends keyof DataLayerEvents>(
  event: T,
  params?: DataLayerEvents[T]
) {
  window.dataLayer.push({
    event,
    ...params,
  });
}
